import { firebaseDatabase } from '../firebase/config'
import store from '@/store'
import utilities from '@/services/utilities'
import _ from 'lodash'
import moment from 'moment'

import Vue from 'vue'
import Vuex from 'vuex'
Vue.use(Vuex)

export async function getPREPData(firebaseDataPath) {
	return new Promise((resolve, reject) => {
		firebaseDatabase.ref(firebaseDataPath).on(
			'value',
			(snapshot) => {
				let response = snapshot.val()
				// console.log('getPREPData::response-->', response)
				if (utilities.objectValidate(store, 'state.ieemPrep.data.final')) {
					store.commit('ieemPrep/SET_STATE', {
						data: _.cloneDeep(response),
						loading: false,
					})
					Vue.prototype.$notification.success({
						message: 'Actualización de datos',
						description: `Se han actualizado los datos al corte de las ${moment(response.final.time).format('YYYY/MM/DD HH:mm:ss')}`,
					})
				} else {
					resolve(response)
				}
			},
			(error) => {
				// console.log('getPREPData::error-->', error)
				reject(error)
			},
		)
	})
}

export function closePREPData(firebaseDataPath) {
	firebaseDatabase.ref(firebaseDataPath).off()
}
