import Vue from 'vue'
import Vuex from 'vuex'
import user from './user'
import settings from './settings'
import ieemPrep from './ieem-prep'

Vue.use(Vuex)

export default new Vuex.Store({
	modules: {
		user,
		settings,
		ieemPrep
	},
	state: {},
	mutations: {},
	actions: {}
})
