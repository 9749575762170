import Vue from 'vue'
import Vuex from 'vuex'
import * as ieemPrepFunctions from '@/services/ieem-prep'
import _ from 'lodash'

Vue.use(Vuex)

export default {
	namespaced: true,
	state: {
		loading: false,
		loadingLabel: 'Obteniendo información',
		data: {},
		firebaseDataPath: '/IEEM',
	},
	mutations: {
		SET_STATE(state, payload) {
			Object.assign(state, {
				...payload,
			})
		},
	},
	actions: {
		GET_ALL_DATA({ commit, dispatch, rootState }) {
			commit('SET_STATE', {
				loading: true,
				loadingLabel: 'Obteniendo información',
			})
			ieemPrepFunctions
				.getPREPData(rootState.ieemPrep.firebaseDataPath)
				.then((response) => {
					// console.log('STORE:getPREPData-->', response)
					commit('SET_STATE', {
						data: _.cloneDeep(response),
					})
				})
				.catch((error) => {
					// console.log('STORE:getPREPData-->', error)
				})
				.finally(() => {
					commit('SET_STATE', {
						loading: false,
					})
				})
		},
		CLOSE({ commit, dispatch, rootState }) {
			ieemPrepFunctions.closePREPData(rootState.ieemPrep.firebaseDataPath)
		},
		UPDATE_DATA_PATH({ commit, dispatch, rootState }, firebaseDataPath) {
			// console.log('UPDATE_DATA_PATH-->', firebaseDataPath)
			dispatch('CLOSE', firebaseDataPath)
			commit('SET_STATE', {
				firebaseDataPath,
			})
			dispatch('GET_ALL_DATA')
		},
		GET_PREP_RESOURCES() {},
	},
	getters: {
		allInformation: (state) => state.data,
		firebaseDataPath: (state) => state.firebaseDataPath,
	},
}
