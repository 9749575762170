<template>
	<div :class="$style.footer">
		<div class="text-right" :class="$style.footerInner">
			<p class="mb-0">
				Hecho con ♥ y un poco de ☕ por el Instituto Politécnico Nacional<br />
				SEPI - ESIME Unidad Culhuacán<br />
			</p>
		</div>
	</div>
</template>

<script>
import { mapGetters } from 'vuex'
import Swal from 'sweetalert2'

export default {
	name: 'Footer',
	computed: {
		...mapGetters('ieemPrep', ['firebaseDataPath']),
	},
	mounted() {
		this.getresourcesPREP()
	},
	methods: {
		handleChange(path) {
			Swal.fire({
				icon: 'info',
				title: `DataPath`,
				html: `Cambio a ${path}`,
				showConfirmButton: false,
				timer: 1500,
			}).then(() => {
				this.$store.dispatch('ieemPrep/UPDATE_DATA_PATH', path)
			})
		},
		getresourcesPREP() {
			this.$store.dispatch('ieemPrep/GET_PREP_RESOURCES')
		},
	},
}
</script>


<style lang="scss" module>
@import './style.module.scss';
</style>
