import Vue from 'vue'
import Router from 'vue-router'
import MainLayout from '@/layouts/Main'

Vue.use(Router)

const router = new Router({
	base: process.env.BASE_URL,
	scrollBehavior() {
		return { x: 0, y: 0 }
	},
	routes: [
		{
			path: '/',
			redirect: 'captura_acta',
			component: MainLayout,
			children: [
				{
					path: '/captura_acta',
					meta: {
						title: 'Captura de actas'
					},
					component: () => import('./views/actas/lists')
				},
				{
					path: '/fix_position',
					meta: {
						title: 'Puntos para corrección de perspectiva'
					},
					component: () => import('./views/actas/fix_position')
				}
			]
		},

		// Redirect to 404
		{
			path: '*',
			redirect: 'auth/404',
			hidden: true
		}
	]
})

export default router
