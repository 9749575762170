import firebase from 'firebase/app'
import 'firebase/auth'
import 'firebase/database'

const firebaseConfig = {
	apiKey: 'AIzaSyAxCANjXf1rqFbEeDQvASrsPw0FQ8fxU7c',
	authDomain: 'validationprep-759e4.firebaseapp.com',
	databaseURL: 'https://validationprep-759e4-default-rtdb.firebaseio.com',
	projectId: 'validationprep-759e4',
}

firebase.initializeApp(firebaseConfig)

export const firebaseAuth = firebase.auth()
export const firebaseDatabase = firebase.database()
