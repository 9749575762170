<template>
	<div :class="$style.topbar">
		<div class="mr-4"></div>
		<div class="mr-auto"></div>
		<div class="mr-4 d-none d-md-block"></div>
		<div class="mb-0 mr-auto d-xl-block d-none"></div>
		<div class="mr-4 d-none d-sm-block"></div>
		<div class="mr-4 d-none d-sm-block"></div>
		<div></div>
	</div>
</template>

<script>
export default {
	components: {},
}
</script>

<style lang="scss" module>
@import './style.module.scss';
</style>
