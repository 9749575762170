export default {
	passwordGenerator() {
		return new Promise((resolve, reject) => {
			const length = 10
			let passwd = ''
			const chars = 'abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789'
			for (let i = 1; i < length; i++) {
				var c = Math.floor(Math.random() * chars.length + 1)
				passwd += chars.charAt(c)
			}
			resolve(passwd)
		})
	},
	sortName(a, b) {
		const elementA = a.name.toUpperCase()
		const elementB = b.name.toUpperCase()

		let comparison = 0
		if (elementA > elementB) {
			comparison = 1
		} else if (elementA < elementB) {
			comparison = -1
		}
		return comparison
	},
	replaceText(text, search, replaceWith) {
		// console.log('utilities::replaceText--', { text, search, replaceWith })
		const result = text.split(search).join(replaceWith)
		return result
	},
	scrollTo(section) {
		// console.log('utilities::scrollTo--', section)
		let element = document.getElementById(section)
		setTimeout(() => {
			element.scrollIntoView({
				behavior: 'smooth',
			})
		}, 150)
	},
	objectValidate(A, keys, defaultValue) {
		if (A) {
			//Compatibilidad con rutas estilo "a/b/c o a.b.c"
			if (typeof keys == 'string') {
				var separator = keys.indexOf('/') >= 0 ? '/' : false
				if (!separator) {
					separator = keys.indexOf('.') >= 0 ? '.' : false
				}
				keys = keys.split(separator)
			}

			if (!(keys instanceof Array)) {
				return defaultValue
			}

			for (var i in keys) {
				A = A[keys[i]]

				if (A === undefined) {
					return defaultValue
				}

				if (!A && i < keys.length - 1) {
					return defaultValue
				}
			}
			return A
		}
		return defaultValue
	},
	objectUpdate(A, keys, value) {
		if (typeof keys == 'string') {
			var separator = keys.indexOf('/') >= 0 ? '/' : false
			if (!separator) {
				separator = keys.indexOf('.') >= 0 ? '.' : false
			}
			keys = keys.split(separator)
		}
		var num = keys.length
		for (var i = 0; i < num - 1; i++) {
			if (A[keys[i]] == undefined) {
				A[keys[i]] = {}
			}
			A = A[keys[i]]
		}
		A[keys[num - 1]] = value
	},
	generarLetra() {
		var letras = ['a', 'b', 'c', 'd', 'e', 'f', '0', '1', '2', '3', '4', '5', '6', '7', '8', '9']
		var numero = (Math.random() * 15).toFixed(0)
		return letras[numero]
	},
	colorHEX() {
		var coolor = ''
		for (var i = 0; i < 6; i++) {
			coolor = coolor + this.generarLetra()
		}
		return '#' + coolor
	},
	onlyUnique(value, index, self) {
		return self.indexOf(value) === index
	},
	getRandomInt(min, max) {
		return Math.floor(Math.random() * (max - min)) + min
	},
}
